export const environment: any = {
  name: 'dev',
  production: false,
  api: 'https://api.dev.myhomeiq.report/api',
  mapsApiKey: 'AIzaSyCyPyVFYCWyES6Nu5FlNOJbL_4nJb0KWiE',
  captcha: '6LdVhFQUAAAAAAtB-EbEKCBBurh_XWaAF172YxNs',
  facebookAppID: '279194842913402',
  homeIqAdmin: 'https://app.dev.myhomeiq.report',
  homeIqReportUrl: 'https://dev.myhomeiq.report',
  landingUrl: 'https://dev.myhomeiq.com',
  sentryDSN: 'https://01bb2c01cb62446c8334b2a6efa8dc20@o325577.ingest.sentry.io/5197102',
  sentryWhitelist: [
    'http://dev.homequityreport.com',
    'https://dev.homequityreport.com',
  ],
  analytics: {
    fb: '358339994660819',
    ga: 'G-LLDKGS5EGK',
    gtm: 'GTM-NF4WHZB3',
  },
};
