<div class="control"
     [class.wide-prepend]="labelStyle && labelStyle === 'wide'"
     [class.control--focus]="focused"
     [class.control--disable]="disabled">
  <div class="control__top-label f-14-normal color-pale-sky mb-1x" *ngIf="label">{{ label }}</div>
  <div class="control__icon" *ngIf="icon" [inlineSVG]="icon" [resolveSVGUrl]="false"></div>
  <form [formGroup]="form">
    <input class="control__input"
           #nativeInput
           type="text"
           formControlName="value"
           [autofocus]="autofocus"
           [class.with-icon]="icon"
           [mask]="mask"
           [prefix]="prefix"
           [thousandSeparator]="thousandSeparator"
           [separatorLimit]="separatorLimit"
           [suffix]="suffix"
           [attr.placeholder]="placeholder"
           [attr.data-lpignore]="lpignore"
           (focus)="focused = true"
           (blur)="blur()"/>
  </form>
</div>
<form-error [name]="formControlName" [model]="errorsModel"></form-error>
