import {
  Component,
  ElementRef,
  Input,
  OnChanges,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'progress-circle',
  templateUrl: './progress-circle.component.html',
  styleUrls: ['./progress-circle.component.sass'],
})
export class ProgressCircleComponent implements OnChanges {

  @ViewChild('progressCircle') circleElement: ElementRef<HTMLOrSVGElement>;

  @Input() total: number;

  @Input() value: number;

  @Input() error: boolean;

  @Input() radius: number = 46;

  circumference: number = 2 * Math.PI * this.radius;

  dashoffset: number;

  ngOnChanges(): void {
    this.progress();
  }

  private progress(): void {
    const progress: number = this.error ? 1 : (this.value + 1) / this.total;

    this.dashoffset = this.circumference * (1 - progress);
  }

}
