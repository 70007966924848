<form class="search" [formGroup]="form" (ngSubmit)="submit()">
  <div class="search-group search-group--address">
    <input class="search-control" #search type="text" placeholder="Enter home address" formControlName="address"/>
    <i class="search-pin"
       [class.error]="getMarkerClass()"
       [inlineSVG]="'/assets/icons/common/pin.svg'"
       [resolveSVGUrl]="false"></i>
  </div>
  <div class="search-group search-group--apt">
    <input class="search-control" type="text" placeholder="Apt." formControlName="unit"/>
    <div class="search-clear" [class.visible]="!!form.get('address').value" (click)="clear()">
      <span>Clear</span>
    </div>
  </div>
  <div class="search-group search-group--submit">
    <iq-button type="submit" label="Get started" [busy$]="action$"></iq-button>
  </div>
  <div class="alert alert--search alert--warning" *ngIf="errors?.length">
    <div *ngFor="let error of errors">{{ error }}</div>
  </div>
</form>
