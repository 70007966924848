<div class="p-10x">
  <iq-button class="m-1x block" label="Button" [busy$]="promiseSetAction" (onClick)="buttonAction()"></iq-button>
  <iq-button class="m-1x block" label="Button" color="white"></iq-button>
  <iq-button class="m-1x block" label="Button" color="grey"></iq-button>
  <iq-button class="m-1x block" label="Button" color="blue-light"></iq-button>
  <iq-button class="m-1x" label="Button"></iq-button>
  <iq-button class="m-1x" label="Button" color="white"></iq-button>
  <iq-button class="m-1x" label="Button" color="grey"></iq-button>
  <iq-button class="m-1x" label="Button" color="blue-light"></iq-button>
  <div class="m-1x"></div>
  <iq-button class="m-1x" label="Button" size="48"></iq-button>
  <iq-button class="m-1x" label="Button" color="white" size="48"></iq-button>
  <iq-button class="m-1x" label="Button" color="grey" size="48"></iq-button>
  <iq-button class="m-1x" label="Button" color="blue-light" size="48"></iq-button>
  <h1 class="f-54-bold lh-tight mb-3x mt-5x">Heading 1</h1>
  <h1 class="f-54-normal lh-tight mb-3x">Heading 1 / Regular</h1>
  <h2 class="f-36-bold lh-snug mb-3x">Heading 2</h2>
  <h2 class="f-36-normal lh-snug mb-3x">Heading 2 / Regular</h2>
  <h3 class="f-24-bold lh-snug mb-3x">Heading 3</h3>
  <h3 class="f-24-normal lh-snug mb-3x">Heading 3 / Regular</h3>
  <h4 class="f-18-bold lh-snug mb-3x">Heading 4</h4>
  <h4 class="f-18-normal lh-snug mb-3x">Heading 4 / Regular</h4>
  <h5 class="f-14-bold lh-tight mb-3x">Heading 5</h5>
  <h5 class="f-14-normal lh-tight mb-7x">Heading 5 / Regular</h5>
  <p class="f-16-normal lh-relaxed mb-2x">Plain text 16px</p>
  <p class="f-18-normal lh-relaxed mb-2x">Plain text 18px</p>
  <p class="italic f-16-600 lh-relaxed mb-7x">Coursive highlight</p>
  <p class="f-13-normal color-pale-sky italic lh-tight mb-2x">Caption coursive</p>
  <p class="f-14-normal color-pale-sky lh-snug mb-7x">Caption 14px</p>
  <p class="f-12-normal color-gray-chateau lh-snug">Disclaimer: All products are not available in all states. All
    options are not available on all programs.</p>
</div>
