import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BaseHttpService } from 'asap-team/asap-tools';

// Consts
import { STEPPER_QUESTIONS_SOURCE } from '@consts/consts';

@Injectable({ providedIn: 'root' })
export class AskQuestionsService {

  constructor(
    private http: BaseHttpService,
  ) {}

  sendQuestion(uid: string, question_text: string, screen_title: string): Observable<unknown> {
    const params: HttpParams = new HttpParams({
      fromObject: {
        uid, question_text, screen_title, source: STEPPER_QUESTIONS_SOURCE,
      },
    });

    return this
      .http
      .post(`v1/seller_digest/digests/${uid}/ask_question`, null, { params });
  }

}
