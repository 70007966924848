<div *ngIf="user?.company_disclaimer_text || user?.disclaimer_text || user?.disclaimer_logo" class="disclaimer">
  <div *ngIf="user?.company_disclaimer_text" class="disclaimer-container mb-2x">
    <div class="disclaimer-text" [innerHtml]="user?.company_disclaimer_text"></div>
  </div>
  <div *ngIf="user?.disclaimer_text" class="disclaimer-container mb-2x">
    <div class="disclaimer-text" [innerHtml]="user?.disclaimer_text"></div>
  </div>
  <div *ngIf="user?.disclaimer_logo" class="disclaimer-logo">
    <img [src]="logo" alt="disclaimer_logo"/>
  </div>
  <div *ngIf="user?.company_disclaimer_text || user?.disclaimer_text || user?.disclaimer_logo"
       class="disclaimer-border"></div>
</div>
