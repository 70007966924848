<div class="send-question">
  <div class="send-question__state state-default" [class.show]="!isQuestionSent">
    <user-avatar class="send-question__avatar" *ngIf="isWithAvatar" [user]="user"></user-avatar>
    <h3 class="send-question__title f-24-bold">{{ mainText }}</h3>
    <p class="send-question__subtitle">Click on a button below to submit your question</p>
    <ul class="send-question__list">
      <li class="send-question__list-item" *ngFor="let question of preparedQuestions; index as questionIndex;">
        <iq-button type="button"
                   [size]="'48'"
                   [label]="question"
                   [color]="'blue-light'"
                   [isWithBorder]="true"
                   [busy$]="questionIsSending(questionIndex) && sendRequestAction$"
                   (click)="selectQuestion(questionIndex)">{{ question }}</iq-button>
      </li>
    </ul>
    <form class="form-group">
      <div class="send-question__group" (clickOutside)="isFormExpanded = false" [class.expanded]="isFormExpanded">
        <input class="control__input form-control"
               id="questionControl"
               type="text"
               name="question"
               autocomplete="off"
               (focus)="isFormExpanded = true"
               [formControl]="questionControl"
               [placeholder]="'or type your own question here...'"/>
        <div class="icon mb-3x send-question__icon"
             [inlineSVG]="'/assets/icons/baloon.svg'"
             [resolveSVGUrl]="false"></div>
        <button class="send-question__submit"
                [disabled]="!questionControl.value"
                [promiseBtn]="sendRequestAction$"
                (click)="sendQuestion(questionControl.value)"></button>
        <div class="invalid-tooltip" *ngIf="questionControl.invalid && isFormExpanded">{{ errorMessage }}</div>
      </div>
    </form>
  </div>
  <div class="send-question__state state-success" [class.show]="isQuestionSent">
    <div class="sent-success__title"><i class="sent-success__icon"
                                        [inlineSVG]="'/assets/icons/check.svg'"
                                        [resolveSVGUrl]="false"></i>
      <div class="sent-success__text">Your question has been submitted.<br/>{{ user.name }} will get in touch with you
        at the earliest convenience.
        If you would like to speak with someone now, give us a call at
        <div class="sent-success__text--bold">{{user?.phone | phone}}</div>
      </div>
    </div>
    <div class="sent-success__actions">
      <iq-button type="button"
                 [size]="'48'"
                 [color]="'blue-light'"
                 [isWithBorder]="true"
                 label="Ask another question"
                 (click)="isQuestionSent = false"></iq-button>
    </div>
  </div>
</div>
