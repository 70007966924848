import { merge } from 'lodash-es';
import { Injectable } from '@angular/core';
import { filter, map, shareReplay, tap } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';
import { BaseHttpService } from 'asap-team/asap-tools';

import type {
  Address,
  LeadForm,
  Lead,
  InterestAddsUp,
  CashOut,
  LoanInfo,
} from '@core/types';
import { UserService } from '../user/user.service';

@Injectable({ providedIn: 'root' })
export class LeadsService {

  private readonly lead: BehaviorSubject<Lead> = new BehaviorSubject<Lead>(null);

  lead$: Observable<Lead> = this
    .lead
    .asObservable()
    .pipe(
      filter<Lead>(Boolean),
      shareReplay({ refCount: false, bufferSize: 1 }),
    );

  constructor(
    private http: BaseHttpService,
    private userService: UserService,
  ) {}

  get getApi(): string {
    return this.userService.isHomeWealthLanding ? 'home_wealth' : 'seller_traffic';
  }

  getLeadValue(): Lead {
    return this.lead.value;
  }

  updateLeadValue(params: LeadForm): void {
    return this.lead.next(merge({}, this.getLeadValue(), params));
  }

  validate(username: string, address: Address): Observable<any> {

    return this.http.post(`v1/${this.getApi}/leads/validate`, { ...address, username });
  }

  search(username: string, params: Address): Observable<Lead> {

    return this
      .http
      .post(`v1/${this.getApi}/leads`, { ...params, username });
  }

  updateLead(username: string, params: LeadForm): Observable<Lead> {
    return this
      .http
      .put(`v1/${this.getApi}/leads/${params.id}`, {
        ...params,
        username,
      })
      .pipe(
        tap((lead: Lead) => {
          const currentLead: Lead = this.getLeadValue();

          this.lead.next(merge({}, currentLead, lead, { lead_type: params.lead_type }));
        }),
      );
  }

  getLead(uid: string): Observable<Lead> {
    return this
      .http
      .get(`v1/seller_digest/leads/${uid}`)
      .pipe(
        tap((lead: Lead) => this.lead.next(lead)),
      );
  }

  getReportFile(id: string, hash: string, report_type?: string): Observable<ArrayBuffer> {
    const options: any = {
      responseType: 'blob',
      observe: 'response',
    };

    if (report_type) {
      options.params = { report_type };
    }

    return this.http.get(`v1/seller_traffic/leads/${id}/reports/${hash}`, options);
  }

  verifyHomeValue(uid: string): Observable<void> {
    return this.http.post(`v1/seller_digest/digests/${uid}/verify_home_value`, null);
  }

  getInterestAddsUp(uid: string): Observable<InterestAddsUp> {
    return this
      .http
      .get(`v1/seller_digest/leads/${uid}/interest_adds_up`)
      .pipe(
        map((response: InterestAddsUp) => {
          response.interest_adds_up = Object.entries(response.interest_adds_up as any);

          return response;
        }),
      );
  }

  getCashOut(uid: string, payload: { loan_term: string; loan_amount: string }): Observable<CashOut> {
    return this
      .http
      .post(`v1/seller_digest/leads/${uid}/cash_out_calculator`, payload);
  }

  updateLoanInfo(uid: string, loanInfo: LoanInfo): Observable<void> {
    return this
      .http
      .patch(`v1/seller_digest/leads/${uid}/update_loan_information`, loanInfo);
  }

}
