import {
  Component,
  Input,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
} from '@angular/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'iq-button',
  templateUrl: './iq-button.component.html',
  styleUrls: ['./iq-button.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IqButtonComponent {

  @Input() type: string = 'button';

  @Input() size: '64' | '48' = '64';

  @Input() color: 'blue' | 'white' | 'grey' | 'blue-light' = 'blue';

  @Input() label: string = null;

  @Input() busy$: Subscription | Promise<any> | boolean = null;

  @Input() disabled: boolean = false;

  @Input() isWithBorder: boolean = false;

  // tslint:disable-next-line: no-output-on-prefix
  @Output() onClick: EventEmitter<Event> = new EventEmitter<Event>();

  get buttonClass(): string {
    return [
      `button--size-${this.size}`,
      `button--color-${this.color}`,
      this.isWithBorder ? 'button--is-with-border' : '',
    ].join(' ');
  }

}
