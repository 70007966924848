import { Route } from '@angular/router';
import { ROUTE } from '@consts/routes';

export const MAIN: Route = {
  path: ROUTE.EMPTY,
  loadChildren: () => import('@modules/main/main.module').then((m: any) => m.MainModule),
};

export const MAIN_HOME_VALUE: Route = {
  path: ROUTE.EMPTY,
  loadChildren: () => import('@modules/main-home-value/main-home-value.module').then((m: any) => m.MainHomeValueModule),
};
