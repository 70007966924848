import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

// Consts
import { ROUTE } from '@consts/routes';
import { LEAD_TYPES } from '@consts/consts';

// Routes
import { MAIN, MAIN_HOME_VALUE } from '@routes/main.route';
import { STEPPER } from '@routes/stepper.route';

// Components
import { NotFoundComponent } from '@modules/common/not-found/not-found.component';
import { DownloadReportComponent } from '@common/components/download-report/download-report.component';
import { UserComponent } from '@modules/common/user/user.component';
import { HomeComponent } from '@modules/common/home/home.component';

// Resolvers
import { HomeResolve } from '@core/resolvers/home/home.resolve';
import { UserResolve } from '@core/resolvers/user/user.resolve';
import { ReportResolve } from '@core/resolvers/report/report.resolve';

const routes: Routes = [
  {
    path: ROUTE.name.NOT_FOUND,
    pathMatch: 'full',
    component: NotFoundComponent,
  },
  {
    path: ':username',
    component: UserComponent,
    resolve: { user: UserResolve },
    children: [
      MAIN,
      STEPPER(),
    ],
  },
  {
    path: `:username/${ROUTE.name.HOME_VALUE}`,
    component: UserComponent,
    data: { isHomeWealthLanding: true },
    resolve: { user: UserResolve },
    children: [
      MAIN_HOME_VALUE,
      STEPPER({ leadType: LEAD_TYPES.SELLING, rootRoute: ROUTE.name.HOME_VALUE }),
    ],
  },
  {
    path: `${ROUTE.name.LEADS}/:id/${ROUTE.name.REPORTS}/:hash`,
    component: DownloadReportComponent,
    resolve: { report: ReportResolve },
  },
  {
    path: ROUTE.EMPTY,
    pathMatch: 'full',
    resolve: { home: HomeResolve },
    component: HomeComponent,
  },
  {
    path: ROUTE.ANY,
    redirectTo: ROUTE.name.NOT_FOUND,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(
      routes,
      {
        scrollPositionRestoration: 'top',
        paramsInheritanceStrategy: 'always',
      },
    ),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
