/* eslint-disable no-underscore-dangle */
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SlideInOutAnimation } from '@common/animations/slide-in-out';

@Component({
  selector: 'accordion-item',
  templateUrl: './accordion-item.component.html',
  styleUrls: ['./accordion-item.component.sass'],
  animations: [SlideInOutAnimation],
})
export class AccordionItemComponent {

  @Input() icon: string = '/assets/icons/accordion-icons/home.svg';

  @Input() title: string = 'Title';

  @Input() text: string = 'Some text';

  @Input() isIndependent: boolean = false;

  @Output() onToggle: EventEmitter<boolean> = new EventEmitter<boolean>();

  animationState: 'in' | 'out' = 'out';

  private _setState: boolean = false;

  @Input() set setState(value: boolean) {
    this._setState = value;
    this.animationState = this._setState ? 'in' : 'out';
  }

  get setState(): boolean {
    return this._setState;
  }

  toggle(): void {
    let isOpened: boolean;

    if (this.isIndependent) {
      this.animationState = this.animationState === 'out' ? 'in' : 'out';

      isOpened = this.animationState === 'in';
    } else {
      isOpened = this.setState;
    }

    this.onToggle.emit(isOpened);
  }

}
