import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import type { Profile } from '@core/types';

// Services
import { UserService } from './user.service';

@Injectable({ providedIn: 'root' })
export class UserServiceProxy {

  profile$: Observable<Profile> = this.userService.profile$;

  constructor(
    private userService: UserService,
  ) {}

}
