import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { DOCUMENT, isPlatformServer } from '@angular/common';
import { SwUpdate } from '@angular/service-worker';
import { interval } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class CheckForUpdateService {

  constructor(
    @Inject(DOCUMENT) private document: Document,
    @Inject(PLATFORM_ID) private platformId: Object,
    updates: SwUpdate,
  ) {
    const idle: number = 15 * 60 * 1000; // 15 min

    if (isPlatformServer(this.platformId)) { return; }

    interval(idle).subscribe(() => updates.checkForUpdate());
  }

}
