import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

import type { Profile } from '@core/types';

@Component({
  selector: 'user-disclaimer',
  templateUrl: 'disclaimer.component.html',
  styleUrls: ['./disclaimer.component.sass'],
})
export class DisclaimerComponent implements OnChanges {

  @Input() profile: Profile;

  user!: Profile;

  ngOnChanges(changes: SimpleChanges): void {
    const user: Profile = changes?.profile.currentValue;

    if (user && user?.partner && user?.role === 'agent') {
      this.user = user.partner;
    } else {
      this.user = user;
    }
  }

  get logo(): string {
    return `assets/icons/disclaimer_logo/${this.user.disclaimer_logo}.svg`;
  }

}
