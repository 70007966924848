import { Resolve, ActivatedRouteSnapshot, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { EMPTY, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

// Services
import { LeadsService } from '@core/services/leads/leads.service';

@Injectable({ providedIn: 'root' })
export class ReportResolve implements Resolve<any> {

  constructor(
    private router: Router,
    private leadsService: LeadsService,
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
  ): Observable<ArrayBuffer> {

    const { id } = route.params;
    const { hash } = route.params;
    const type: string = route.queryParams.report_type;

    return this
      .leadsService
      .getReportFile(id, hash, type)
      .pipe(
        catchError(() => {
          this.router.navigate(['/']);

          return EMPTY;
        }),
      );
  }

}
