<div class="footer">
  <p class="f-14-normal lh-tight">&copy; {{ year }} Home Equity Report.</p>
  <div class="footer-links">
    <a class="footer-links__item f-14-normal lh-tight" [href]="landingUrl + '/privacy-policy/'" target="_blank">
      Privacy Policy
    </a>
    <a class="footer-links__item f-14-normal lh-tight" [href]="landingUrl + '/terms-of-use/'" target="_blank">
      Terms of Use
    </a>
    <a class="footer-links__item f-14-normal lh-tight" href="https://www.nmlsconsumeraccess.org/" target="_blank">
      nmlconsumeraccess.org
    </a>
  </div>
</div>
