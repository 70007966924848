import { Component, Inject, PLATFORM_ID, OnInit } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';

import type { Profile } from '@core/types';

// Consts
import { USER_ROLE } from '@consts/consts';

// Services
import { GoogleMapsApiLoaderService } from '@core/helpers/google-maps-api-loader/google-maps-api-loader.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.sass'],
})
export class AppComponent implements OnInit {

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private route: ActivatedRoute,
    private title: Title,
    private googleMapsApiLoaderService: GoogleMapsApiLoaderService,
  ) {}

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.googleMapsApiLoaderService.init();
    }

    this.setDefaultTitle();
  }

  private setDefaultTitle(): void {
    const user: Profile = this.route.snapshot.data?.user;

    if (!user) { return; }

    const title: string = user.role === USER_ROLE.agent ? 'Home Equity Report' : 'Explore Refinance Options';

    this.title.setTitle(title);
  }

}
