import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

import type { Profile } from '@core/types';

// Consts
import { USER_ROLE } from '@consts/consts';

// Services
import { UserService } from '@core/services/user/user.service';
import { AsaplyticsService } from '@core/helpers/asaplytics/asaplytics.service';

@Component({
  selector: 'contact-info',
  templateUrl: './contact-info.component.html',
  styleUrls: ['./contact-info.component.sass'],
})
export class ContactInfoComponent implements OnChanges {

  @Input() user: Profile;

  @Input() copy: { title: string; subTitle: string } = {
    title: null,
    subTitle: null,
  };

  @Input() showIcon: boolean = true;

  userInfo!: Profile;

  constructor(
    private userService: UserService,
    private asaplyticsService: AsaplyticsService,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    const user: Profile = changes?.user.currentValue;

    if (user && user?.partner && user?.role === 'agent') {
      this.userInfo = user.partner;
    } else {
      this.userInfo = user;
    }
  }

  get title(): string {
    if (!this.copy?.title) {
      if (this.userService.isUserRole(USER_ROLE.agent) && !this.userService.isHomeWealthLanding) {
        return 'Connect with a real estate professional';
      }

      return 'Connect with a mortgage professional';
    }

    return this.copy.title;
  }

  get subTitle(): string {
    if (!this.copy?.subTitle) {
      if (this.userService.isUserRole(USER_ROLE.agent)) {
        return 'Whether you just prefer a friendly voice or need more information, our licensed real estate professional is ready to guide you.';
      }

      return 'Whether you just prefer a friendly voice or need more information, our licensed loan officer & real estate professionals are ready to guide you.';
    }

    return this.copy.subTitle;
  }

  get isAgent(): boolean {
    return this.userService.isUserRole(USER_ROLE.agent);
  }

  getMessengerUrl(username: string): string {
    if (!username) { return ''; }

    return `https://www.facebook.com/messages/t/${username}`;
  }

  track(username: string): void {
    if (!username) { return; }

    this.asaplyticsService.clickUserContactInfo();
  }

}
