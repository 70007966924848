import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { Sentinel } from 'asap-team/asap-tools';

// Consts
import { environment } from './environments/environment';

// Modules
import { AppModule } from './app/modules/app.module';

if (environment.production) {
  enableProdMode();
}

const sentinel: Sentinel = new Sentinel(environment);

sentinel.onguard();

document.addEventListener('DOMContentLoaded', () => {
  platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch((err: any) => console.log(err));
});
