import type { Dictionary } from 'asap-team/asap-tools';
import type { Option, UserRole } from '@core/types';

export * from './tracking';
export * from './form-errors';

export const WARNING: Dictionary<any> = { NO_NETWORK: 'No Internet Connection' };

export const ERROR: Dictionary<any> = {
  SOMETHING_WENT_WRONG: 'Something went wrong',
  DEFAULT_SEARCH_ERROR: 'We couldn\'t find that address. Start typing you address and select one of the suggested options from a dropdown list.',
};

export const USER_ROLE: UserRole = {
  agent: 'Realtor',
  lender: 'Loan Officer',
};

export const STEPPER_QUESTIONS_SOURCE: string = 'refi_stepper';

export const MONTHS: Option[] = [
  { label: 'January', value: 1 },
  { label: 'February', value: 2 },
  { label: 'March', value: 3 },
  { label: 'April', value: 4 },
  { label: 'May', value: 5 },
  { label: 'June', value: 6 },
  { label: 'July', value: 7 },
  { label: 'August', value: 8 },
  { label: 'September', value: 9 },
  { label: 'October', value: 10 },
  { label: 'November', value: 11 },
  { label: 'December', value: 12 },
];

export const LEAD_TYPES: Dictionary = {
  SELLING: 'selling',
  REFINANCE: 'refinance',
};
