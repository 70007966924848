import { Component, Input } from '@angular/core';
import type { Observable } from 'rxjs';

// Services
import { GoogleMapsApiLoaderService } from '@core/helpers/google-maps-api-loader/google-maps-api-loader.service';

@Component({
  selector: 'property-map',
  templateUrl: './property-map.component.html',
  styleUrls: ['./property-map.component.sass'],
})
export class PropertyMapComponent {

  @Input() lat: number;

  @Input() lng: number;

  mapOptions: google.maps.MapOptions = {
    zoom: 20,
    mapTypeId: 'satellite',
    zoomControl: false,
    scaleControl: false,
    mapTypeControl: false,
    disableDefaultUI: true,
    draggable: false,
    gestureHandling: 'none',
  };

  markerOptions: google.maps.MarkerOptions = {
    draggable: false,
    icon: 'assets/images/map_icon.svg',
  };

  isMapsReady$: Observable<boolean> = this.googleMapsApiLoaderService.load();

  constructor(
    private googleMapsApiLoaderService: GoogleMapsApiLoaderService,
  ) {}

}
