<section class="contact-info">
  <div class="icon mb-3x"
       *ngIf="showIcon"
       [inlineSVG]="'/assets/images/main/profile/online_chat.svg'"
       [resolveSVGUrl]="false"></div>
  <h2 class="title mb-3x f-36-bold lh-snug" [innerHTML]="title"></h2>
  <p class="subtitle f-18-normal lh-relaxed" [innerHTML]="subTitle"></p>
  <div class="preview m-t-6x">
    <div class="logo">
      <user-avatar [user]="user"></user-avatar>
    </div>
    <div class="contact-info__info">
      <h3 class="name f-24-bold lh-relaxed">{{ user?.name }}</h3>
      <p class="license f-14-normal lh-tight color-pale-sky">
        <span *ngIf="user?.job_title"><b>{{ user?.job_title }}</b> • </span>
        <span *ngIf="!user?.partner && !isAgent">NMLS&#32;</span>
        <span *ngIf="user?.partner || isAgent">LIC#&#32;</span>
        {{ user?.license_number }}
      </p>
      <p class="f-14-normal lh-tight mb-2x color-pale-sky" *ngIf="user?.company_name && user?.company_license_number">
        <b>{{ user.company_name }}</b> •&#32;
        <span *ngIf="!user?.partner && !isAgent">NMLS&#32;</span>
        <span *ngIf="user?.partner || isAgent">LIC#&#32;</span>
        {{ user.company_license_number }}
      </p>
      <a class="contact f-18-normal lh-relaxed text-overflow" href="tel: +1{{ user?.phone }}">
        {{ user?.phone | phone }}
      </a>
      <a class="contact f-18-normal lh-relaxed text-overflow" href="mailto: {{ user?.email }}">
        {{ user?.email }}
      </a>
      <div class="socials__list m-b-4x">
        <a *ngIf="user?.socials?.facebook_url"
          class="socials__list--link"
          [href]="user?.socials?.facebook_url"
          target="_blank">
          <img src="assets/icons/socials/facebook.svg" alt="facebook-link">
        </a>
        <a *ngIf="user?.socials?.linkedin_url"
          class="socials__list--link"
          [href]="user?.socials?.linkedin_url"
          target="_blank">
          <img src="assets/icons/socials/linkedin.svg" alt="linkedin-link">
        </a>
        <a *ngIf="user?.socials?.instagram_url"
          class="socials__list--link"
          [href]="user?.socials?.instagram_url"
          target="_blank">
          <img src="assets/icons/socials/instagram.svg" alt="instagram-link">
        </a>
        <a *ngIf="user?.socials?.youtube_url"
          class="socials__list--link"
          [href]="user?.socials?.youtube_url"
          target="_blank">
          <img src="assets/icons/socials/youtube.svg" alt="youtube-link">
        </a>
        <a *ngIf="user?.socials?.zillow_url"
          class="socials__list--link"
          [href]="user?.socials?.zillow_url"
          target="_blank">
          <img src="assets/icons/socials/zillow.svg" alt="youtube-link">
        </a>
        <a *ngIf="user?.socials?.realtor_url"
          class="socials__list--link"
          [href]="user?.socials?.realtor_url"
          target="_blank">
          <img src="assets/icons/socials/realtor.svg" alt="youtube-link">
        </a>
        <a *ngIf="user?.socials?.personal_website_url"
          class="socials__list--link"
          [href]="user?.socials?.personal_website_url"
          target="_blank">
          <img src="assets/icons/socials/website.svg" alt="youtube-link">
        </a>
      </div>
    </div>
    <a class="chat-btn"
      *ngIf="user?.fb_messenger"
      [href]="getMessengerUrl(user?.fb_messenger)"
      target="_blank"
      (click)="track(user?.name)">
      Chat
    </a>
  </div>
  <div *ngIf="user?.partner" class="preview m-t-2x">
    <div class="logo">
      <user-avatar [user]="userInfo"></user-avatar>
    </div>
    <div class="contact-info__info">
      <h3 class="name f-24-bold lh-relaxed">{{ userInfo?.name }}</h3>
      <p class="license f-14-normal lh-tight color-pale-sky">
        <span *ngIf="userInfo?.job_title"><b>{{ userInfo?.job_title }}</b> • </span>
        <span>NMLS</span> {{ userInfo?.license_number }}
      </p>
      <p class="f-14-normal lh-tight mb-2x color-pale-sky" *ngIf="userInfo?.company_name && userInfo?.company_license_number">
        <b>{{ userInfo.company_name }}</b> •&#32;
        <span>NMLS&#32;</span>
        {{ userInfo.company_license_number }}
      </p>
      <a class="contact f-18-normal lh-relaxed text-overflow" href="tel: +1{{ userInfo?.phone }}">
        {{ userInfo?.phone | phone }}
      </a>
      <a class="contact f-18-normal lh-relaxed text-overflow" href="mailto: {{ userInfo?.email }}">
        {{ userInfo?.email }}
      </a>
      <div class="socials__list m-b-4x">
        <a *ngIf="userInfo?.socials?.facebook_url"
          class="socials__list--link"
          [href]="userInfo?.socials?.facebook_url"
          target="_blank">
          <img src="assets/icons/socials/facebook.svg" alt="facebook-link">
        </a>
        <a *ngIf="userInfo?.socials?.linkedin_url"
          class="socials__list--link"
          [href]="userInfo?.socials?.linkedin_url"
          target="_blank">
          <img src="assets/icons/socials/linkedin.svg" alt="linkedin-link">
        </a>
        <a *ngIf="userInfo?.socials?.instagram_url"
          class="socials__list--link"
          [href]="userInfo?.socials?.instagram_url"
          target="_blank">
          <img src="assets/icons/socials/instagram.svg" alt="instagram-link">
        </a>
        <a *ngIf="userInfo?.socials?.youtube_url"
          class="socials__list--link"
          [href]="userInfo?.socials?.youtube_url"
          target="_blank">
          <img src="assets/icons/socials/youtube.svg" alt="youtube-link">
        </a>
        <a *ngIf="userInfo?.socials?.zillow_url"
          class="socials__list--link"
          [href]="userInfo?.socials?.zillow_url"
          target="_blank">
          <img src="assets/icons/socials/zillow.svg" alt="youtube-link">
        </a>
        <a *ngIf="userInfo?.socials?.realtor_url"
          class="socials__list--link"
          [href]="userInfo?.socials?.realtor_url"
          target="_blank">
          <img src="assets/icons/socials/realtor.svg" alt="youtube-link">
        </a>
        <a *ngIf="userInfo?.socials?.personal_website_url"
          class="socials__list--link"
          [href]="userInfo?.socials?.personal_website_url"
          target="_blank">
          <img src="assets/icons/socials/website.svg" alt="youtube-link">
        </a>
      </div>
    </div>
    <a class="chat-btn"
      *ngIf="userInfo?.fb_messenger"
      [href]="getMessengerUrl(userInfo?.fb_messenger)"
      target="_blank"
      (click)="track(userInfo?.name)">
      Chat
    </a>
  </div>
</section>
