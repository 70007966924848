import { ActivatedRoute } from '@angular/router';
import {
  Component,
  OnInit,
  ElementRef,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { HttpResponse } from '@angular/common/http';

@Component({
  selector: 'app-download-report',
  templateUrl: './download-report.component.html',
  styleUrls: ['./download-report.component.sass'],
})
export class DownloadReportComponent implements OnInit {

  @ViewChild('link', { static: true }) elem: ElementRef;

  constructor(
    private activatedRoute: ActivatedRoute,
    private renderer: Renderer2,
  ) {}

  ngOnInit(): void {
    this.initDownload(this.activatedRoute.snapshot.data.report);
  }

  private initDownload(file: HttpResponse<File>): void {
    if (!file) { return; }

    const fileName: string[] = file.headers.get('content-disposition').split('"').splice(1, 1);
    const url: string = window.URL.createObjectURL(file.body);

    this.renderer.setAttribute(this.elem.nativeElement, 'href', url);
    this.renderer.setAttribute(this.elem.nativeElement, 'download', `${fileName}`);
    this.elem.nativeElement.click();
  }

}
