<div class="contacts" [class.show-contacts]="toggleNavigation">
  <div class="contacts-wrap" (click)="toggleNavigation = true">
    <div class="contacts__info">
      <div class="contacts__info-name">{{(user$ | async)?.name}}</div>
      <div class="f-11-normal color-pale-sky lh-tight">
        Talk to {{(user$ | async)?.name}} today
      </div>
      <div class="contacts__info-phone">{{ (user$ | async)?.phone | phone }}</div>
    </div>
    <div class="contacts__avatar">
      <user-avatar [user]="user$ | async"></user-avatar>
    </div>
    <div class="contacts-icon flex-center"></div>
  </div>
  <div class="contacts-mobile" (click)="toggleNav()"></div>
</div>
<div class="navbar">
  <div class="navbar__wrap">
    <div class="navbar__user">
      <div class="navbar__user-details">
        <div class="navbar__avatar">
          <div class="navbar__avatar-wrap">
            <user-avatar [user]="user$ | async"></user-avatar>
          </div>
        </div>
      </div>
      <div class="navbar__name" [attr.title]="(user$ | async)?.name">{{ (user$ | async)?.name }}</div>
      <div class="navbar__license">
        <span> <b>{{((user$ | async)?.job_title) || ((user$ | async)?.role) }}&#32;</b>•&#32;</span>
        <span *ngIf="!isAgent">NMLS&#32;</span>
        <span *ngIf="isAgent">DRE&#32;</span>
        {{ (user$ | async)?.license_number }}
      </div>
      <a class="navbar__email f-w-400"
         href="mailto: {{ (user$ | async)?.email }}"
         [attr.title]="(user$ | async)?.email">
        {{ (user$ | async)?.email }}
      </a>
      <a class="navbar__phone f-w-400"
         href="tel: +1{{ (user$ | async)?.phone }}"
         [attr.title]="(user$ | async)?.phone | phone">
        {{ (user$ | async)?.phone | phone }}
      </a>
      <div class="socials__list" *ngIf="(user$ | async)?.socials">
        <a class="socials__list--link"
           *ngIf="(user$ | async)?.socials?.facebook_url"
           [href]="(user$ | async)?.socials?.facebook_url"
           target="_blank">
          <img src="assets/icons/socials/facebook.svg" alt="facebook-link"/>
        </a>
        <a class="socials__list--link"
           *ngIf="(user$ | async)?.socials?.instagram_url"
           [href]="(user$ | async)?.socials?.instagram_url"
           target="_blank">
          <img src="assets/icons/socials/instagram.svg" alt="instagram-link"/>
        </a>
        <a class="socials__list--link"
           *ngIf="(user$ | async)?.socials?.linkedin_url"
           [href]="(user$ | async)?.socials?.linkedin_url"
           target="_blank">
          <img src="assets/icons/socials/linkedin.svg" alt="linkedin-link"/>
        </a>
        <a class="socials__list--link"
           *ngIf="(user$ | async)?.socials?.youtube_url"
           [href]="(user$ | async)?.socials?.youtube_url"
           target="_blank">
          <img src="assets/icons/socials/youtube.svg" alt="youtube-link"/>
        </a>
        <a class="socials__list--link"
           *ngIf="(user$ | async)?.socials?.zillow_url"
           [href]="(user$ | async)?.socials?.zillow_url"
           target="_blank">
          <img src="assets/icons/socials/zillow.svg" alt="zillow-link"/>
        </a>
        <a class="socials__list--link"
           *ngIf="(user$ | async)?.socials?.realtor_url"
           [href]="(user$ | async)?.socials?.realtor_url"
           target="_blank">
          <img src="assets/icons/socials/realtor.svg" alt="realtor-link"/>
        </a>
        <a class="socials__list--link"
           *ngIf="(user$ | async)?.socials?.personal_website_url"
           [href]="(user$ | async)?.socials?.personal_website_url"
           target="_blank">
          <img src="assets/icons/socials/website.svg" alt="website-link"/>
        </a>
      </div>
      <div class="navbar__btn-group" *ngIf="(user$ | async)?.fb_messenger">
        <a class="navbar__btn"
           *ngIf="(user$ | async)?.name as username"
           [href]="getMessengerUrl((user$ | async)?.fb_messenger)"
           target="_blank"
           (click)="trackClickUserContactInfo(username)">
          <img src="assets/images/contacts/messenger.svg" alt="messenger"/>
          <span class="navbar__btn-label">Chat</span>
        </a>
      </div>
    </div>
  </div>
</div>
