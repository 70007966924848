<div class="container">
  <div class="home-logo"><img class="home-logo-link" src="assets/icons/search-page-logo.svg" alt="Logo"/></div>
  <div class="file"><img src="assets/images/download/group.svg" alt="File"/></div>
  <div class="information">
    <p class="information__download-report">Downloading your equity report…</p>
    <p class="information__text-link">
      If your download doesn't start automatically please
      <a #link>click here</a>
    </p>
  </div>
</div>
