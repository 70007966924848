import { Directive, ElementRef, AfterViewInit, Input } from '@angular/core';

@Directive({ selector: '[autofocus]' })
export class AutofocusDirective implements AfterViewInit {

  @Input() autofocus: boolean;

  constructor(
    private element: ElementRef,
  ) {}

  ngAfterViewInit(): void {
    if (this.autofocus) {
      this.element.nativeElement.focus();
    }
  }

}
