import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { DOCUMENT, isPlatformServer } from '@angular/common';
import { SwUpdate } from '@angular/service-worker';

@Injectable({ providedIn: 'root' })
export class ForceUpdateService {

  constructor(
    @Inject(DOCUMENT) private document: Document,
    @Inject(PLATFORM_ID) private platformId: Object,
    updates: SwUpdate,
  ) {
    if (isPlatformServer(this.platformId)) { return; }

    updates.available.subscribe(() => {
      updates.activateUpdate().then(() => this.document.location.reload());
    });
  }

}
