import { NgModule, ErrorHandler } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule, BrowserTransferStateModule } from '@angular/platform-browser';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import {
  APIInterceptor,
  API_INTECEPTOR_CONFIG,
  JSONApiDeserializerInterceptor,
  SentinelErrorHandler,
  SENTINEL_CONFIG,
  USER_SERVICE_TOKEN,
} from 'asap-team/asap-tools';

// Consts
import { environment } from 'environments/environment';

// Modules
import { AppRoutingModule } from '@routes/app-routing.module';
import { CommonsModule } from '@common/commons.module';

// Components
import { NotFoundComponent } from '@modules/common/not-found/not-found.component';
import { StyleGuideComponent } from '@modules/common/style-guide/style-guide.component';
import { UserComponent } from '@modules/common/user/user.component';
import { HomeComponent } from '@modules/common/home/home.component';

// Services
import { TrackingScriptsService } from '@core/helpers/tracking-scripts/tracking-scripts.service';
import { ForceUpdateService } from '@core/helpers/force-update/force-update.service';
import { CheckForUpdateService } from '@core/helpers/check-for-update/check-for-update.service';
import { UserServiceProxy } from '@core/services/user/user.service.proxy';

import { AppComponent } from './app.component';

@NgModule({
  declarations: [
    AppComponent,
    UserComponent,
    HomeComponent,
    StyleGuideComponent,
    NotFoundComponent,
  ],
  imports: [
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    BrowserTransferStateModule,
    CommonModule,
    CommonsModule,
    HttpClientModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
  ],
  providers: [
    {
      provide: API_INTECEPTOR_CONFIG,
      useValue: {
        api: environment.api,
        bypass: [
          'assets',
          'googleapis',
          'rtclx',
        ],
      },
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: APIInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JSONApiDeserializerInterceptor,
      multi: true,
    },
    {
      provide: SENTINEL_CONFIG,
      useValue: { environment, logErrors: true },
    },
    {
      provide: ErrorHandler,
      useClass: SentinelErrorHandler,
    },
    {
      provide: USER_SERVICE_TOKEN,
      useClass: UserServiceProxy,
    },
    ForceUpdateService,
    CheckForUpdateService,
    TrackingScriptsService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
