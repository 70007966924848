import { DOCUMENT } from '@angular/common';
import { Component, Inject, HostListener, ElementRef } from '@angular/core';
import { Observable } from 'rxjs';

import type { Profile, UserRole } from '@core/types';

// Consts
import { USER_ROLE } from '@consts/consts';

// Services
import { UserService } from '@core/services/user/user.service';
import { AsaplyticsService } from '@core/helpers/asaplytics/asaplytics.service';

@Component({
  selector: 'app-contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.sass'],
})
export class ContactsComponent {

  user$: Observable<Profile> = this.userService.profile$;

  USER_ROLE: UserRole = USER_ROLE;

  toggleNavigation: boolean = false;

  @HostListener('document:click', ['$event.target'])
  onClickOutside(element: Element): void {
    const elementClicked: any = this.elementRef.nativeElement.contains(element);

    if (this.toggleNavigation && !elementClicked) {
      this.toggleNav();
    }
  }

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private elementRef: ElementRef,
    private userService: UserService,
    private asaplyticsService: AsaplyticsService,
  ) {}

  toggleNav(): void {
    this.toggleNavigation = !this.toggleNavigation;

    if (this.toggleNavigation && window.innerWidth < 800) {
      this.document.body.classList.add('overflow-hidden');
    } else {
      this.document.body.classList.remove('overflow-hidden');
    }
  }

  getMessengerUrl(username: any): string {
    if (!username) { return ''; }

    return `https://www.facebook.com/messages/t/${username}`;
  }

  trackClickUserContactInfo(username: string): void {
    if (!username) { return; }

    this.asaplyticsService.clickUserContactInfo();
  }

  get isHomeWealth(): boolean {
    return this.userService.isHomeWealthLanding;
  }

  get isAgent(): boolean {
    return this.userService.isUserRole(USER_ROLE.agent);
  }

}
