<button class="button"
        #btn
        [type]="type"
        [attr.aria-label]="label"
        [ngClass]="buttonClass"
        [disabled]="disabled"
        [promiseBtn]="busy$"
        (click)="onClick.emit($event)">
  <span class="button-label" *ngIf="label">{{ label }}</span>
  <div class="busy"></div>
</button>
