import {
  Directive,
  ElementRef,
  AfterViewInit,
  Output,
  EventEmitter,
  QueryList,
  ContentChildren,
  Input,
} from '@angular/core';
import { fromEvent } from 'rxjs';
import { delay, first, tap } from 'rxjs/operators';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Directive({ selector: '[listAnimationEnd]' })
export class ListAnimationEndDirective implements AfterViewInit {

  @ContentChildren('listAnimationItem') list: QueryList<ElementRef>;

  @Input() delayBeforeAnimation: number = 800;

  @Output() listAnimationEnd: EventEmitter<void> = new EventEmitter<void>();

  ngAfterViewInit(): void {
    if (!this.list.toArray().length) {
      console.warn('List of animated elements is not defined');

      return;
    }

    fromEvent(this.list.last.nativeElement, 'animationend')
      .pipe(
        first(),
        delay(this.delayBeforeAnimation),
        tap(() => this.listAnimationEnd.next()),
        untilDestroyed(this),
      )
      .subscribe();
  }

}
