const ANY: string = '**';
const EMPTY: string = '';
const LEADS: string = 'leads';
const NOT_FOUND: string = 'not-found';
const PROPERTY: string = 'property';
const REPORTS: string = 'reports';
const STYLE_GUIDE: string = 'style-guide';
const STEPPER: string = 'stepper';
const HOME_VALUE: string = 'home-value';

export const ROUTE: any = {
  EMPTY,
  ANY,
  name: {
    NOT_FOUND,
    LEADS,
    PROPERTY,
    REPORTS,
    STEPPER,
    STYLE_GUIDE,
    HOME_VALUE,
  },
  alias: {},
};
