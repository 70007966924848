import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-logo',
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.sass'],
  encapsulation: ViewEncapsulation.Emulated,
})
export class LogoComponent {

  @Input() logo: string;

}
