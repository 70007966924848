import { Injectable } from '@angular/core';
import { every } from 'lodash-es';
import { ResponseErrorHandler } from 'asap-team/asap-tools';

import type { FormattedAddress } from '@core/types';

@Injectable({ providedIn: 'root' })
export class GooglePlacesService {

  constructor(
    private responseErrorHandler: ResponseErrorHandler,
  ) {}

  remapGooglePlacesAddress(data: google.maps.GeocoderAddressComponent[]): FormattedAddress {
    const result: any = {};

    data.forEach((item: any) => {
      result[item.types[0]] = {
        long_name: item.long_name,
        short_name: item.short_name,
      };
    });

    // [TEMP] Related to GMaps bug https://issuetracker.google.com/issues/35830425

    if (result?.administrative_area_level_1?.long_name === 'New York') {
      result.locality = { ...result.administrative_area_level_1 };
    }

    return result;
  }

  validateGooglePlacesAddress(data: FormattedAddress, form: any, cb: Function): boolean {
    const fields: string[] = [
      'country',
      'postal_code',
      'locality',
      'administrative_area_level_3',
      'neighborhood',
      'administrative_area_level_1',
      'street_number',
      'route',
    ];

    const validator = (field: string): boolean => {
      const isPropertyExists = (object: FormattedAddress, property: string): boolean => {
        // City case
        if (['locality', 'administrative_area_level_3', 'neighborhood'].some((v: string) => v === property)) {
          return !!object?.locality || !!object?.administrative_area_level_3 || !!object?.neighborhood;
        }

        return !!object[property];
      };

      const result: boolean = isPropertyExists(data, field);

      if (!result) {
        const prettyFieldName: string = this.prettifyFiledName(field);

        form.controls.address.setErrors({ incorrect: true });

        cb(this.responseErrorHandler.processWithoutControls([
          { source: { pointer: prettyFieldName }, detail: 'is blank or doesn\'t exist' },
        ]));
      }

      return result;

    };

    return every(fields, validator);
  }

  private prettifyFiledName(data: string): string {
    switch (data) {
      case 'administrative_area_level_1': {
        return 'state';
      }
      case 'locality': {
        return 'city';
      }
      default: {
        return data;
      }
    }
  }

}
