/// <reference types="googlemaps" />
import {
  OnInit,
  NgZone,
  Component,
  ViewChild,
  ElementRef,
  Input,
} from '@angular/core';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { Subscription } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';

import { APIErrorResponse, Dictionary, ResponseErrorHandler } from 'asap-team/asap-tools';

import type {
  Profile,
  Address,
  UserRole,
  FormattedAddress,
  Lead,
} from '@core/types';

// Constants
import { ERROR, USER_ROLE } from '@consts/consts';
import { ROUTE } from '@consts/routes';

// Services
import { LeadsService } from '@core/services/leads/leads.service';
import { GooglePlacesService } from '@core/services/google-places/google-places.service';
import { AsaplyticsService } from '@core/helpers/asaplytics/asaplytics.service';
import { GoogleMapsApiLoaderService } from '@core/helpers/google-maps-api-loader/google-maps-api-loader.service';
import {TypedFormGroup} from '@common/types/form-group-config.type';

interface AddressQuery {
  address: string;
  unit: string;
}

@UntilDestroy()
@Component({
  selector: 'property-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.sass'],
})
export class SearchComponent implements OnInit {

  @ViewChild('search', { static: true }) searchElementRef: ElementRef;

  @Input() user: Profile;

  @Input() addressFromQuery: Dictionary<string>;

  USER_ROLE: UserRole = USER_ROLE;

  form: FormGroup;

  errors: string[] = [];

  address: Address;

  action$: Subscription;

  constructor(
    private fb: FormBuilder,
    private ngZone: NgZone,
    private router: Router,
    private route: ActivatedRoute,
    private leadsService: LeadsService,
    private googlePlacesService: GooglePlacesService,
    private responseErrorHandlerService: ResponseErrorHandler,
    private asaplyticsService: AsaplyticsService,
    private googleMapsApiLoaderService: GoogleMapsApiLoaderService,
  ) { }

  ngOnInit(): void {
    this.form = this.fb.group<TypedFormGroup<AddressQuery>>({
      address: [this.addressFromQuery?.address || '', Validators.required],
      unit: [{ value: this.addressFromQuery?.unit || '', disabled: true }],
    });
    this.subscribes();

    if (this.addressFromQuery?.encodedPlace) {
      const decodedAddress: object = JSON.parse(atob(this.addressFromQuery.encodedPlace));

      this.handleAddressChange(decodedAddress);

      if (
        this.address.country
        && this.address.zip
        && this.address.state
        && this.address.city
        && this.address.street
      ) {
        this.submit();
      }

    }
  }

  getMarkerClass(): boolean {
    const control: AbstractControl = this.form.controls.address;

    return (!control.valid && control.touched) || !!this.errors.length;
  }

  submit(): void {
    this.asaplyticsService.trackClickGetStarted();
    this.resetError();

    if (!this.form.value?.address) {
      this.errors.push(ERROR.DEFAULT_SEARCH_ERROR);

      return;
    }

    const { unit } = this.form.value;

    if (unit) {
      this.address = { ...this.address, unit };
    }

    this.action$ = this
      .leadsService
      .validate(this.user.username, this.address)
      .pipe(
        switchMap(() => this.leadsService.search(this.user.username, this.address)),
        untilDestroyed(this),
      )
      .subscribe(
        (lead: Lead) => {
          this.router.navigate([`${ROUTE.name.STEPPER}/${lead.uid}`], { relativeTo: this.route });
        },
        (error: APIErrorResponse) => {
          this.asaplyticsService.trackClickGetStartedError();
          this.errors = this.responseErrorHandlerService.processWithoutControls(error?.error?.errors);
        },
      );
  }

  clear(): void {
    this.form.reset();
    this.resetError();
  }

  private subscribes(): void {
    this
      .form
      .valueChanges
      .pipe(
        debounceTime(100),
        distinctUntilChanged(),
        untilDestroyed(this),
      )
      .subscribe(
        (value: any) => {
          const { address } = value;

          if (address) {
            this.form.controls.unit.enable();

            return;
          }

          this.form.controls.unit.disable();
        },
      );

    this.initGoogleAutoSuggest();
  }

  private resetError(): void {
    this.errors = [];
  }

  private initGoogleAutoSuggest(): void {
    this
      .googleMapsApiLoaderService
      .load()
      .pipe(
        untilDestroyed(this),
      )
      .subscribe((result: boolean) => {
        if (!result) { return; }

        const autocomplete: google.maps.places.Autocomplete = new google.maps.places.Autocomplete(
          this.searchElementRef.nativeElement, { types: ['address'] },
        );

        autocomplete.setComponentRestrictions({ country: ['us'] });

        const suggest = (): void => {
          this.ngZone.run(() => {
            this.resetError();

            const place: google.maps.places.PlaceResult = autocomplete.getPlace();

            this.handleAddressChange(place);
          });
        };

        autocomplete.addListener('place_changed', suggest);
      });
  }

  private handleAddressChange(event: any): void {
    if (!event.address_components) { return; }

    const address: FormattedAddress = this
      .googlePlacesService
      .remapGooglePlacesAddress(event.address_components);

    const addressValid: boolean = this
      .googlePlacesService
      .validateGooglePlacesAddress(address, this.form, (error: any) => {
        this.errors = [
          ...this.errors,
          ...error,
        ];
      });

    if (addressValid) {
      this.address = {
        country: address.country.long_name,
        zip: address.postal_code.long_name,
        state: address.administrative_area_level_1.short_name,
        city: address?.locality?.long_name || address?.administrative_area_level_3?.long_name || address?.neighborhood?.long_name,
        street: `${address.street_number.long_name} ${address.route.long_name}`,
        lat: event.lat || event.geometry.location.lat(),
        lng: event.lng || event.geometry.location.lng(),
        place_id: event.place_id || event.place_id,
      };

      this.form.controls.unit.enable();
      this.asaplyticsService.trackInputSearch();
    }
  }

}
