import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { isPlatformServer } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import {
  catchError,
  filter,
  map,
} from 'rxjs/operators';

// Consts
import { environment } from 'environments/environment';

@Injectable({ providedIn: 'root' })
export class GoogleMapsApiLoaderService {

  private apiLoaded: BehaviorSubject<boolean> = new BehaviorSubject(null);

  private apiLoaded$: Observable<boolean> = this.apiLoaded.asObservable().pipe(
    filter<boolean>(Boolean),
  );

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private http: HttpClient,
  ) {}

  init(): void {
    if (isPlatformServer(this.platformId)) { return; }

    this
      .http
      .jsonp(
        `https://maps.googleapis.com/maps/api/js?key=${environment.mapsApiKey}&libraries=places&language=en-US`,
        'callback',
      )
      .pipe(
        map(() => true),
        catchError(() => {
          this.apiLoaded.next(false);

          return of(false);
        }),
      )
      .subscribe((result: boolean) => {
        if (!result) { return; }

        this.apiLoaded.next(true);
      });
  }

  load(): Observable<boolean> {
    if (isPlatformServer(this.platformId)) { return of(false); }

    return this.apiLoaded$;
  }

}
