import { Component } from '@angular/core';

@Component({
  templateUrl: './style-guide.component.html',
  styleUrls: ['./style-guide.component.sass'],
})
export class StyleGuideComponent {

  promiseSetAction: Promise<void>;

  buttonAction(): void {
    this.promiseSetAction = new Promise((resolve: any) => {
      setTimeout(resolve, 2000);
    });
  }

}
