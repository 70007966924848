<video class="not-found__bg" preload="true" loop muted autoplay>
  <source src="assets/images/error404.webm" type="video/webm"/>
</video>
<div class="not-found flex-column flex-center">
  <p class="not-found__title">page not found</p>
  <div class="not-found__line"></div>
  <p class="not-found__sub">Try to search here:</p>
  <a class="not-found__link" routerLink="/oleg-minzu">homequityreport.com/oleg-minzu</a>
  <a class="not-found__link" routerLink="/sean-safholm">homequityreport.com/sean-safholm</a>
  <img class="not-found__logo" src="assets/images/logo.svg" alt="not found"/>
</div>
