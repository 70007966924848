import { EMPTY, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import {
  Resolve,
  ActivatedRouteSnapshot,
  Router,
} from '@angular/router';
import { catchError, tap } from 'rxjs/operators';
import { ThemeService } from 'asap-team/asap-tools';

import { Profile } from '@core/types';

// Services
import { UserService } from '@core/services/user/user.service';

@Injectable({ providedIn: 'root' })
export class UserResolve implements Resolve<Profile> {

  constructor(
    private router: Router,
    private userService: UserService,
    private themeService: ThemeService,
  ) {}

  resolve({ params, data }: ActivatedRouteSnapshot): Observable<Profile> {
    const { username } = params;

    this.userService.setlandingType(!!data.isHomeWealthLanding);

    return this.userService.getUser(username).pipe(
      tap((response: Profile) => {
        this.themeService.setTheme(response.theme || 'flamingo');
      }),
      catchError(() => {
        this.router.navigate(['/']);

        return EMPTY;
      }),
    );
  }

}
