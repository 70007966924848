import { FormErrors } from 'asap-team/asap-tools';

import { requiredError, apiError } from './conditions';

export const emailStepErrors: FormErrors = {
  email: [
    requiredError,
    {
      name: 'pattern',
      text: 'Email is invalid',
      rules: ['touched', 'dirty'],
    },
    apiError,
  ],
  global: [],
};
