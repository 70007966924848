<div class="control"
     [class.wide-prepend]="labelStyle && labelStyle === 'wide'"
     [class.control--focus]="focused"
     [class.control--disable]="disabled">
  <div class="control__top-label f-14-normal color-pale-sky mb-1x" *ngIf="label">{{ label }}</div>
  <div class="control__icon" [inlineSVG]="icon" [resolveSVGUrl]="false"></div>
  <input class="control__input with-icon"
         #nativeInput=""
         [autofocus]="autofocus"
         type="text"
         [attr.placeholder]="placeholder"
         [attr.data-lpignore]="lpignore"
         (focus)="focused = true"
         (blur)="blur()"
         (input)="input($event)"/>
</div>
<form-error [name]="formControlName" [model]="errorsModel"></form-error>
