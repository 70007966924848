import { Data, Route } from '@angular/router';
import { ROUTE } from '@consts/routes';

export const STEPPER = (data?: Data): Route => {
  const route: Route = {
    path: ROUTE.name.STEPPER,
    loadChildren: () => import('@modules/stepper/stepper.module').then((m: any) => m.StepperModule),
    ...(data && { data }),
  };

  return route;
};
