import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientJsonpModule, HttpClientModule } from '@angular/common/http';
import { CommonModule, CurrencyPipe, DecimalPipe } from '@angular/common';

import { Angular2PromiseButtonModule } from 'angular2-promise-buttons';
import { GoogleMapsModule } from '@angular/google-maps';
import { NgxMaskModule } from 'ngx-mask';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { RouterModule } from '@angular/router';
import { ToastrModule } from 'ngx-toastr';
import { AsapCommonModule } from 'asap-team/asap-tools';

// Components
import { AccordionItemComponent } from '@common/components/accordion-item/accordion-item.component';
import { AskQuestionsComponent } from '@common/components/ask-questions/ask-questions.component';
import { ContactInfoComponent } from '@common/components/contact-info/contact-info.component';
import { ContactsComponent } from '@common/components/contacts/contacts.component';
import { DisclaimerComponent } from '@common/components/disclaimer/disclaimer.component';
import { FooterComponent } from '@common/components/footer/footer.component';
import { FormErrorComponent } from '@common/components/form-error/form-error.component';
import { IqButtonComponent } from '@common/components/iq-button/iq-button.component';
import { IqFormControlAmountComponent } from '@common/forms/form-controls/iq-form-control-amount/iq-form-control-amount.component';
import { IqFormControlMaskComponent } from '@common/forms/form-controls/iq-form-control-mask/iq-form-control-mask.component';
import { IqFormControlNativeSelectComponent } from '@common/forms/form-controls/iq-form-control-native-select/iq-form-control-native-select.component';
import { IqFormControlTextComponent } from '@common/forms/form-controls/iq-form-control-text/iq-form-control-text.component';
import { LogoComponent } from '@common/components/logo/logo.component';
import { ProgressCircleComponent } from '@common/components/progress-circle/progress-circle.component';
import { PropertyMapComponent } from '@common/components/property-map/property-map.component';
import { SearchComponent } from '@common/components/search/search.component';
import { UserAvatarComponent } from '@common/components/user-avatar/user-avatar.component';

// Directives
import { AutofocusDirective } from '@common/directives/autofocus/autofocus.directive';
import { ListAnimationEndDirective } from '@common/directives/list-animation-end/list-animation-end.directive';

const shared: any = [
  ContactInfoComponent,
  AskQuestionsComponent,
  AccordionItemComponent,
  ContactsComponent,
  DisclaimerComponent,
  FooterComponent,
  FormErrorComponent,
  IqButtonComponent,
  IqFormControlAmountComponent,
  IqFormControlMaskComponent,
  IqFormControlNativeSelectComponent,
  IqFormControlTextComponent,
  LogoComponent,
  ProgressCircleComponent,
  SearchComponent,
  UserAvatarComponent,
  PropertyMapComponent,
  AutofocusDirective,
  ListAnimationEndDirective,
];

const dialogs: any = [];

const imports: any = [
  Angular2PromiseButtonModule.forRoot({
    spinnerTpl: '',
    btnLoadingClass: 'is-loading',
  }),
  CommonModule,
  FormsModule,
  AsapCommonModule,
  NgxMaskModule.forRoot(),
  InlineSVGModule.forRoot({ baseUrl: '/assets/icons/' }),
  ReactiveFormsModule,
  RouterModule,
  ToastrModule.forRoot({
    maxOpened: 1,
    autoDismiss: true,
    preventDuplicates: true,
    positionClass: 'toast-bottom-center',
    toastClass: 'notify',
  }),
  GoogleMapsModule,
];

const exported: any = [
  Angular2PromiseButtonModule,
  CommonModule,
  FormsModule,
  HttpClientModule,
  HttpClientJsonpModule,
  NgxMaskModule,
  InlineSVGModule,
  ReactiveFormsModule,
  AsapCommonModule,
];

const declarations: any = [];

const pipes: any = [
  CurrencyPipe,
  DecimalPipe,
];

@NgModule({
  imports,
  exports: [
    ...exported,
    ...dialogs,
    ...shared,
  ],
  declarations: [
    ...declarations,
    ...dialogs,
    ...shared,
  ],
  providers: [
    ...pipes,
  ],
})
export class CommonsModule {}
