<svg class="progress-svg" width="100" height="100" viewBox="0 0 100 100">
  <circle class="progress-value"
          #progressCircle
          [class.red]="error"
          [style.strokeDasharray]="circumference"
          [style.strokeDashoffset]="dashoffset"
          [attr.r]="radius"
          stroke-width="4"
          cx="50"
          cy="50"></circle>
</svg>
